import { call, put, select } from "redux-saga/effects";
import { AppState } from "../..";
import api from "../../../api";
import { recaptcha } from "../../../tools/recaptcha";
import { ThenReturnType } from "../../types";
import { takeLeadingPattern } from "../../utils";
import { recoveryCheckCodeFailureAction, recoveryCheckCodeSuccessAction } from "./recovery.check_code.actions";
import { recoverySendPasswordFailureAction, recoverySendPasswordSuccessAction } from "./recovery.send_password.actions";
import { recoverySendSmsFailureAction, recoverySendSmsSuccessAction } from "./recovery.send_sms.actions";
import { RecoveryAction } from "./recovery.types";

export function* loginRecoverySendSmsRequestSaga(action: RecoveryAction) {
  const getToken3 = async () => {
    await recaptcha.ready();
    return recaptcha.execute({ action: 'password_recovery_send_sms' })
  }
  const _tokenv3: ThenReturnType<typeof getToken3> = yield call(getToken3);
  const token3 = _tokenv3?.token3;
  const _firstRequestParams = { ...action.params, token3 }
  let res: ThenReturnType<typeof api.login.sendPasswordRecoveryCode> = yield call(api.login.sendPasswordRecoveryCode, _firstRequestParams)
  const recaptchaRes = res.data?.message?.sign ? res.data.message : res.data;
  if (res.error && recaptchaRes?.sign && recaptchaRes?.date) {
    const _tokenv2: ThenReturnType<typeof recaptcha['render']> = yield call(recaptcha.render, 'captcha')
    const token2 = _tokenv2?.token2;
    const { sign, nonce, date } = recaptchaRes;
    const _secondRequestParams = { ...action.params, token3, token2, sign, nonce, date }
    res = yield call(api.login.sendPasswordRecoveryCode, _secondRequestParams)
  }
  if (res.error) {
    yield put(recoverySendSmsFailureAction())
  } else {
    yield put(recoverySendSmsSuccessAction())
  }
}

export function* loginRecoveryCheckCodeRequestSaga(action: RecoveryAction) {
  const res: ThenReturnType<typeof api.login.checkPasswordRecoveryCode> = yield call(api.login.checkPasswordRecoveryCode, action.params)
  if (res.error) {
    yield put(recoveryCheckCodeFailureAction())
  } else {
    yield put(recoveryCheckCodeSuccessAction(res.data.users))
  }
}

export function* loginRecoverySendPasswordRequestSaga(action: RecoveryAction) {
  const res: ThenReturnType<typeof api.login.recoverPassword> = yield call(api.login.recoverPassword, action.params)
  if (res.error) {
    yield put(recoverySendPasswordFailureAction())
  } else {
    yield put(recoverySendPasswordSuccessAction())
  }
}

export default function* watchLogin() {
  yield takeLeadingPattern<RecoveryAction['type']>('RECOVERY_SEND_SMS_REQUEST', loginRecoverySendSmsRequestSaga, recoverySendSmsFailureAction)
  yield takeLeadingPattern<RecoveryAction['type']>('RECOVERY_CHECK_CODE_REQUEST', loginRecoveryCheckCodeRequestSaga, recoveryCheckCodeFailureAction)
  yield takeLeadingPattern<RecoveryAction['type']>('RECOVERY_SEND_PASSWORD_REQUEST', loginRecoverySendPasswordRequestSaga, recoverySendPasswordFailureAction)
}